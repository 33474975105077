@font-face {
    font-family: KongshoMJ;
    src: url('./KongshoMJ\ Bold.ttf') format('truetype');
}
@font-face {
    font-family: Ubuntu;
    src: url('./Ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

.KongshoMJ {
    font-family: KongshoMJ;
}
.Ubuntu {
    font-family: Ubuntu;
}

/*  Dev font */
@font-face {
    font-family: devfont;
    src: url('./Berkshire_Swash/BerkshireSwash-Regular.ttf') format('truetype');
}
.devfont {
    font-family: devfont;
}