@font-face {
    font-family: SutonnyMJ;
    src: url('./assets/fonts/SutonnyMJ\ Regular.ttf') format('truetype');
}
@font-face {
    font-family: SutonnyOMJ;
    src: url('./assets/fonts/SutonnyOMJ\ Regular.ttf') format('truetype');
}
@font-face {
    font-family: KongshoMJ;
    src: url('./assets/fonts/KongshoMJ\ Bold.ttf') format('truetype');
}

@font-face {
    font-family: KongshoMatraMJ;
    src: url('./assets/fonts/KongshoMatraMJ\ Bold.ttf') format('truetype');
}

@font-face {
    font-family: Ubuntu;
    src: url('./assets/fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
}
@font-face {
    font-family: Oswald;
    src: url('./assets/fonts/Oswald/static/Oswald-Bold.ttf') format('truetype');
}


@font-face {
    font-family: devfont;
    src: url('./assets/fonts/Berkshire_Swash/BerkshireSwash-Regular.ttf') format('truetype');
}
.devfont {
    font-family: devfont;
}


::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  border-radius: 100vw;
  margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #E54E77;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: tomato;
}
