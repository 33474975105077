/* .css-y0txs9-MuiPaper-root-MuiDrawer-paper {
    background-color: #3c486b;
} */


    
@media (max-width: 319.5px) {
    #headerBanarRight, #SchoolNameEnglish, #SchoolNameBangla, .latest{
    display: none !important;
    
    }
    
    
    #schoolLogo{
        width: 1.6rem !important;
    }
   }
    
@media (min-width: 320px) and (max-width: 425.5px) {
    .latest{
    display: none !important;
    }
    #SchoolNameEnglish{
        font-size: 0.8rem !important;
        display: block;
        margin-top: 0.41rem;
        letter-spacing: -0.05rem;
    }
    #SchoolNameBangla{
        display: block;
        font-size: 1.1rem !important;
        letter-spacing: 0rem;
    }
    
    #schoolLogo{
        width: 2.55rem !important
    }
   }
    
@media (min-width: 426px) and (max-width: 768px) {
    #SchoolNameEnglish{
        font-size: 1.1rem !important;
        display: block;
        margin-top: 0.41rem;
        letter-spacing: 0rem;
    }
    #SchoolNameBangla{
        display: block;
        font-size: 1.4rem !important;
        letter-spacing: 0rem;
    }
    #schoolLogo{
        width: 2.8rem !important;
    }
   }

@media (min-width: 1px) and (max-width: 1024px) {
    #desktopMenu, #headerBanarRightImg, #AuthLog {
    display: none;
    }

    #SchoolNameEnglish{
        font-size: 2rem;
        margin-top: 0.41rem;
    }
    #SchoolNameBangla{
        font-size: 2.6rem;
    }
    #schoolLogo{
        width: 5rem;
    }
   
   }

@media (min-width: 1025px) and (max-width: 4000px) {
    #desktopMenu, #headerBanarRightImg {
    display: block;
    }
    #mobileMenu, #mobileMenu1{
        display: none;
    }
   
   }

   
@media (min-width: 1080px) {
    #mobileMenu{
        display: none;
    }
} 

/* //===================================== Header End =====================================// */


 .scrolling-text {
        animation: marquee 10s linear infinite;
    }

    @keyframes marquee {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }



    .banglaSchool{
        color: #F9D949;

    }
    .banglaSchool::after {
        content: "";
        background: url('./stickers/pen.png') no-repeat bottom;
        background-size: 1.3em 1em; /* 100px/16 = 6.25em, 65px/16 = 4.0625em */
        width: 1.3em;  /* 100px/16 = 6.25em */
        height: 1em; /* 65px/16 = 4.0625em */
        margin-bottom: -0.2375em; /* -15px/16 = -0.9375em */
        filter: drop-shadow(0.05em 0.05em 0.1em rgba(255, 255, 255, 0.5)); /* 5px/16 = 0.3125em, 3px/16 = 0.1875em, 10px/16 = 0.625em */
        display: inline-block;
    }


  .headerBanar{
    
  }