.marquee-containerMain {
  overflow: hidden;
  border: 1px solid #3C486B;
  background: #F45050;
  border-radius: 1px;
  margin-top: 0.5em;
  margin-bottom: 5px;
  font-size: 1.5em;
  height: 1.5em; /* Adjust the height to your desired value */
  display: flex;
  align-items: center;
}

.marquee-container {
  overflow: hidden;
  border: 1px solid #F45050;
  background: #fff;
  border-radius: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 1.5em; /* Adjust the height to your desired value */
  width: 100%;
  display: flex;
  align-items: center;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 25s linear infinite;
}

.scroll-notice-link {
    color: #3C486B !important;
    font-size: 0.9em !important;
    text-decoration: none;
}

.scroll-notice-link:hover {
    color: #32a047 !important;
    text-decoration: none;
}

.marquee-icon {
    color: #d1d1d1 !important;
    font-size: 0.6em !important;
    margin-right: 0.5em;
    padding: 1em;
    display: inline-block;
    white-space: nowrap;
    background-color: #3C486B;
}


@media (max-width: 768px) {
  .latest {
    display: none;
  }

  .scroll-notice-link {
    font-size: 0.7em !important;
}
.marquee-icon{
   font-size: 0.7em !important;
   padding: 0.5rem;
}
}